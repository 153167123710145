import RootLayout from '@/layouts/root';
import type { AppProps } from 'next/app';
import '@/styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { useSessionUser } from '@/hooks/useSessionUser';

export default function App({ Component, pageProps }: AppProps) {
  const { fetchSessionUser } = useSessionUser();

  React.useEffect(() => {
    fetchSessionUser();
  }, []);

  return (
    <RootLayout>
      <Component {...pageProps} />
    </RootLayout>
  );
}
