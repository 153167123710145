import Axios, { AxiosRequestConfig } from 'axios';
import { Endpoints, ErrorResponse } from '@/types/endpoints';

export const axios = Axios.create({
  responseType: 'json',
  withCredentials: true,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

axios.interceptors.response.use(
  (response) => {
    const { data, status } = response;
    if (data.limit) {
      return {
        data: data.data,
        limit: data.limit,
        offset: data.offset,
        total: data.total,
      };
    }
    if ('error' in data) {
      return data;
    }
    if ('data' in data) {
      return data.data;
    }
    return data;
  },
  (error) => {
    if (error.response?.status === 401) {
      const roomsRegex = new RegExp(`^\/rooms\/`);
      if (!roomsRegex.test(location.pathname) && location.pathname !== '/') {
        location.href = `${process.env.NEXT_PUBLIC_APP_HOST}`;
      }
      return Promise.resolve(error.response.data);
    }
    return Promise.reject(error);
  },
);

axios.interceptors.request.use(async (request) => {
  let params = request.params;
  if (['post', 'patch'].includes(request.method ?? '')) {
    // FormData対応
    if (request.data instanceof FormData) {
      params = {};
      for (let [key, value] of request.data.entries()) {
        params[key] = value;
      }
    } else {
      params = request.data;
    }
  }

  if (request.url) {
    request.url = replaceUrl(request.url, params);
  }
  return request;
});

/** :idのような文字列をparamsの値に置換する処理 */
function replaceUrl<T extends { [key: string]: any }>(
  url: string,
  params: T,
): string {
  (url.match(/:([a-zA-Z0-9]+)/g) || []).forEach((value) => {
    const index = value.replace(/:/i, '');
    if (params[index]) {
      url = url.replace(new RegExp(value, 'i'), params[index]);
      delete params[index];
    }
    value = value.replace(/:/i, '');
  });

  return url;
}

export const api: Endpoints = {
  get<T = any>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    return axios.get(endpoint, config);
  },
  post<T = any>(
    endpoint: string,
    data: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return axios.post(endpoint, data, config);
  },
  patch<T = any>(
    endpoint: string,
    data: any,
    config?: AxiosRequestConfig,
  ): Promise<T> {
    return axios.patch(endpoint, data, config);
  },
  delete<T = any>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    return axios.delete(endpoint, config);
  },
};

export default api;
