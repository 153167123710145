import * as React from 'react';
import api from '@/libs/api';
import { useSessionUserStore } from './stores/useSessionUserStore';

export function useSessionUser() {
  const { user, setUser, isFetching, isCompleted, setFetching, setCompleted } =
    useSessionUserStore();
  const fetchSessionUser = React.useCallback(async () => {
    if (isFetching || user) return;
    setFetching(true);
    const res = await api.get('/api/v1/session-user');
    setFetching(false);
    if ('error' in res) {
      setCompleted(true);
      return;
    }
    setUser(res);
  }, [isFetching, user, setUser]);

  const refresh = React.useCallback(async () => {
    setFetching(true);
    const res = await api.get('/api/v1/session-user');
    setFetching(false);
    if ('error' in res) {
      setCompleted(true);
      return;
    }
    setUser(res);
  }, []);

  const signup = async (name: string) => {
    const res = await api.post('/api/v1/users', { name });
    if ('error' in res) {
      return;
    }
    refresh();
    return res;
  };

  return { user, isCompleted, fetchSessionUser, refresh, signup };
}
