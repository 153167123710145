import { Kaisei_Opti } from 'next/font/google';
import { ToastContainer } from 'react-toastify';

const kaisei = Kaisei_Opti({ weight: '400', subsets: ['latin'] });

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className={`${kaisei.className} bg-darkGreen-900`}>
      {children}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        closeButton={false}
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}
